.test-start-img {
    transform: rotate(-95deg);
}

.test-start-img > img {
    width: 100%;
    height: 100%;
}

.test-end-img > img {
    width: 100%;
    height: 100%;
}

.test-end-img {
    z-index: 200;
    position: relative;
    transform: rotate(83deg);
}

.test-invitation-wrapper {
    width: 180%;
    margin-left: -45%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.rose-white-background-second {
    position: absolute;
    bottom: 0vh;
    background-image: url(https://storage.yandexcloud.net/arsenal-images/rose-pattern.png);
    background-repeat: no-repeat;
    width: 50%;
    height: 25vh;
    right: -25vh;
    z-index: 100;
    background-color: #ededed;
    background-blend-mode: color-dodge;
    opacity: 1;
}

@media (orientation: portrait) {
    .test-invitation-wrapper {
        margin-top: 10vh;
        width: 100%;
        margin-left: unset;
        flex-flow: column;
    }


    .test-start-img,
    .test-end-img {
        width: 18%;
        top: 50%;
        position: absolute;
    }

    .test-start-img {
        order: 2;
        left: 10%;
        transform: rotate(-115deg);
    }
    
    .test-end-img {
        transform: rotate(100deg);
        order: 1;
        right: 10%;
    }
}