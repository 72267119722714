.loader-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #f7f7f7;
    z-index: 10000;
}

.loader-img-wrapper {
    margin: auto;
    height: auto;
    width: 45%;
    animation: animatedLogo;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

@media (orientation: portrait) {
  .loader-img-wrapper {
    width: 95%;
}
}

.loader-img-wrapper > img {
  width: 100%;
}

@keyframes animatedLogo {
      0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
}

.img-fade-out {
    animation: fadeOut;
    animation-duration: 0.5s;
    opacity: 0.5;
}

@keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
}