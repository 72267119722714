.start-wear-armour-wrapper {
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
}

.start-wear-armour-wrapper-background {
    width: 70%;
    height: 70vh;
    background-image: url(https://storage.yandexcloud.net/arsenal-images/rose-pattern.png);
    background-color: #ededed;
    background-blend-mode: screen;
    opacity: 0.7;
    z-index: 100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
}

.start-wear-armour-img-wrapper {
    position: relative;
    height: auto;
    width: 40%;
    padding: 5vh;
    margin: 5vh;
    z-index: 500;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: color-burn;
    /* background-color: #ededed; */
    background-image: url(https://storage.yandexcloud.net/arsenal-images/wear_armour/armour_trasperent.png);
}

.start-wear-armour-img-wrapper > .armour-launch-img {
    height: 100%;
    width: 100%;
}

.armour-start-icon {
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    width: 30%;
    top: 10%;
}