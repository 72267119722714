
.video-container-wrapper {
    margin: auto;
}

.screen-shot-buttons-wrapper {
    position: absolute;
    bottom: 3vh;
    display: flex;
    width: 100%;
    justify-content: center;
}

.create-screen-shot-button {
    background-color: rgba(225,225,225, 0.6);
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    margin: 0 1vw;
}

.create-screen-shot-button > img {
    margin: auto;
    width: 60%;
    height: auto;
}

.camera-error {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wear_preview {
    width: 15%;
    bottom: 0;
    position: absolute;
    cursor: pointer;
}

.wear_preview > img {
    width: 100%;
    height: 100%;
}


@media (orientation: portrait) {

    .create-screen-shot-button {
        width: 8vw;
        height: 8vw;
    }

    .wear_preview {
        display: none;
    }

}
