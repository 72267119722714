body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

:after, :before {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cormorant Garamond', sans-serif;
  margin-bottom: 24px;
}

h1 {
  font-size: 4rem;
  font-weight: 400;
}

h2 {
  font-size: 3.5rem;
  font-weight: 400;
}

h3 {
  font-size: 2.5rem;
  font-weight: 400;
}

h4 {
  font-size: 2rem;
  font-weight: 400;
}

h5 {
  font-family: 'Montserrat', sans-serif;
  color: #707070;
  font-size: 1.5rem;
  font-weight: 400;
}

span {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  line-height: 1.4rem;
  display: block;
  color: #707070;
}

.app-wrapper {
  background-color: #efefef;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
  width: 100vw;
  overflow-x: hidden;
  padding-bottom: 10vh;
}

.tips {
  color: darkgoldenrod;
  font-weight: bolder;
  font-style: italic;
}

.container {
  width: 65%
}

.numbers {
  font-size: 2rem;
  line-height: 2.6rem;
}

.hidden {
  display: none
}

.text-container {
  width: 70%;
  margin: auto;
}

.button {
  height: 5vh;
  width: 246px;
  display: flex;
  cursor: pointer;
  padding: 14px;
  background: #9c3f1e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  z-index: 300;
}

.button > span {
  color: #fff
}

.close-button {
  position: absolute;
  cursor: pointer;
  top: 1vh;
  right: 1vw;
  font-family: sans-serif;
  font-weight: 500;
  color: #9c3f1e;
} 

.red {
  color: #9c3f1e;
}

.pointer {
  cursor: pointer;
}



@media (orientation: portrait) {
  h1 {
    font-size: 7vw;
  }
  
  h2 {
    font-size: 1.7rem;
  }
  
  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.1rem;
  }

  h5 {
    font-size: 1rem;
  }

  .container {
    width: 100%
  }

  span {
    font-size: 3.5vw;
    line-height: 1.4;
  }


  .numbers {
    font-size: 6vw;
  }

}