.double-slider-container {
    position: relative;
    height: 60vh;
    width: 100vw;
    margin-left: -27.5%;
    display: flex;
    flex-flow: column;
}

.double-slider-wrapper {
    height: 90%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.single-carousel-wrapper {
    width: 50%;
    margin: auto;
}

.single-carousel-wrapper > span {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-sliders-wrapper {
    display: flex;
    width: 90%;
}

.arrow {
    text-align: center;
    z-index: 600;
    margin: auto;
    font-size: 3vw;
    cursor: pointer;
}

.item {
    text-align: center;
    color: white;
    font-size: 40px;
    transition: height 1.5s, width 1.5s, left 1.5s,  background-color 1.5s;
}

.item > img {
    width: 100%;
    height: 100%;
}

/* // $level2: 7vw;

// $level1: 25vw;

// $level0: 7vw; */

.level1 {
    height: 35vw;
    width: 35vw;
    background-color: transparent;
    z-index: 500;
}

.level0,
.level2 {
    height: 7vw;
    width: 7vw;
    background-color: transparent;
    margin-top: 40%;
    z-index: 400;
}

.level2 {
    margin-right: -8%;
}

.level0 {
    margin-left: -8%;
}

.slider-counter-wrapper {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-counter {
        height: 1vh;
        width: 1vh;
        border-radius: 50%;
        background-color:#707070;
        margin: 8px;
}

.active-slider-counter {
    background-color: #fff;
}


/* // .left-enter {
//     opacity: 0;
//     height: $level2 - 30;
//     width: $level2 - 20;
//     line-height: $level2 - 30;
// }

// .left-enter.left-enter-active {
//     opacity: 1;
//     height: $level2;
//     width: $level2;
//     line-height: $level2;
//     margin-top: 25px;
//     transition: left 1.5s, opacity 1.5s, height 1.5s, width 1.5s;
// }

// .left-leave {
//     opacity: 1;
//     height: $level2;
//     width: $level2;
//     line-height: $level2;
//     margin-top: 25px;
// }

// .left-leave.left-leave-active {
//     transition: left 1.5s, height 1.5s, width 1.5s;
// }

// .right-enter {
//     transition: left 1.5s, height 1.5s, width 1.5s;

// }

// .right-enter.right-enter-active {
//     transition: right 1.5s, height 1.5s, width 1.5s;
// }


// .right-leave.right-leave-active {
//     transition: right 1.5s, opacity 1.5s, height 1.5s, width 1.5s;
// } */

.noselect {
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none;  
            user-select: none;
}

@media (orientation: portrait) {
    .double-slider-container {
        width: unset; 
        margin-left: unset;
    }

    .img-sliders-wrapper {
        flex-direction: column;
        width: 100%;
    }

    .single-carousel-wrapper {
        width: unset;
        margin-bottom: 5vh;
    }

    .level1 {
        height: 50vw;
        width: 50vw;
    }
    
    .level0,
    .level2 {
        height: 15vw;
        width: 15vw;
    }

    .arrow {
        display: none
    }
}