  .caption-block {
    margin-top: 2vh;
    z-index: 500;
  }

  .caption-toggle {
    display: flex;
  }
  
  .caption-img {
    width: auto;
    height: 2vw;
    margin: 0 2vw;
    cursor: pointer;
  }

  .caption-additional-text {
    margin-top: 24px;
  }


@media (orientation: portrait) {  
    .caption {
      font-size: 3.5vw;
      align-self: unset;
      text-align: unset;
      width: 100%;
    }

    .caption-img {
      height: 5vh;
      margin: 0 2vh;
  }
}