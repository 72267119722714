.floor-img-description-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.floor-img-wrapper {
    width: 60%;
    height: auto;
}

.floor-descriptions-wrapper {
    width: 30%;
}

.floor-descriptions-wrapper > span {
    line-height: 1.8rem;
}

.floor-img-wrapper > img {
    width: 100%;
    height: 100%;
}

.floor-one-description-wrapper {
    display: flex;
}


@media (orientation: portrait) {  

    .plan-container {
        width: 80%;
    }

    .floor-img-description-wrapper {
        flex-flow: column;
    }

    .floor-descriptions-wrapper > span {
        line-height: unset;
    }
    
    .floor-img-wrapper {
        width: 100%;
        margin-bottom: 5vh;
    }
    .floor-descriptions-wrapper {
        width: 100%;
    }
}