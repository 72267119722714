.test-wrapper {
    position: absolute;
    top: 10vh;
    bottom: 10vh;
    left: 10vw;
    right: 10vw;
    border: 0;
    box-shadow: 0 0 20px rgba(128, 76, 52, 0.5);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 0;
    background-color: #efefef;
    background-image: url(https://storage.yandexcloud.net/arsenal-images/red-dust.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.stage-template-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
}

.stage-result-wrapper {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100%;
}

.test-img-wrapper {
    height: 85%;
    width: auto;
    margin: auto;
    transform: rotate(-17deg);
}

.test-img-wrapper > img {
height: 100%;
width: auto;
}

.test-result-wrapper {
    display: flex;
    width: 80%;
    height: 95%;
    margin: auto;
}

.result-text-wrapper {
    margin: 15% auto;

}

.test-block-wrapper {
    width: 60%;
    height: 80%;
    display: flex;
    flex-flow: column;
    margin: 5% auto;
}

.test-block-wrapper > h2 {
    color: #a84220;
}

.stage-template-answers-wrapper > div {
    margin: 5vh;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.stage-template-answers-wrapper > div:hover > .test-circle {
    background-color: #707070;
}

.test-circle {
    height: 1vh;
    width: 1vh;
    border-radius: 50%;
    border: 1px solid #707070;;
    margin: 8px;
}

.stage-counter-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    width: 95%;
    height: 5vh;
    margin: auto;
}

.stage-counter { 
    width: 19%;
    height: 3px;
    background-color: #9d482a;
    margin: 8px;
}
@media (orientation: portrait) {
    .test-img-wrapper {
        transform: unset;
    }
    .stage-template-answers-wrapper > div:hover > .test-circle {
        background-color: transparent;
    }
}